import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'

import { getFileURL, getProductURL, getSlug } from '../utils/url'
import ManufacturerBadge from './manufacturer-badge'

const ProductCard = ({ product }) => {
	const { title, sku, shortDescription, manufacturer } = product
	const slug = getSlug(product)
	const productURL = getProductURL(slug)

	const image = product.image || {
		asset: {
			gatsbyImageData: {
				images: {
					fallback: {
						src: 'https://via.placeholder.com/256x256',
					},
				},
			},
		},
	}

	return (
		<div
			className="product uk-card uk-card-default uk-card-hover uk-margin"
			data-manufacturer={manufacturer ? getSlug(manufacturer) : null}
		>
			<div
				className="uk-card-body uk-padding-small uk-padding-remove-bottom"
				data-uk-grid
			>
				{/* manufacturer badge */}
				{manufacturer && <ManufacturerBadge manufacturer={manufacturer} />}

				{/* image */}
				<div className="uk-width-1-1@s uk-width-1-4@m uk-flex uk-flex-middle uk-flex-center">
					<Link to={productURL}>
						<GatsbyImage
							className="uk-display-block uk-margin-auto"
							image={getImage(image.asset)}
							style={{ maxHeight: 256 }}
							imgStyle={{ objectFit: 'contain', maxHeight: 256 }}
							alt={`Imagem do produto ${sku}`}
						/>
					</Link>
				</div>

				{/*  short description */}
				<div className="uk-width-expand ">
					<h3 className="uk-card-title">
						<Link to={productURL} className="uk-link-heading">
							{title}
						</Link>
					</h3>
					<p className="uk-text-meta">REF: {sku || 'N/A'}</p>
					<p
						className="short_description"
						dangerouslySetInnerHTML={{ __html: shortDescription }}
					></p>
				</div>
			</div>

			<div className="uk-card-footer">
				<div className="uk-grid-small" data-uk-grid>
					<div className="uk-width-expand"></div>
					<div className="uk-width-auto">
						<Link to={productURL} className="uk-button uk-button-text">
							Saiba Mais
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

// export const productCardDataFragment = graphql`
// 	fragment ProductCardDataFragment on SanityProduct {
// 		title
// 		slug {
// 			current
// 		}
// 		sku
// 		manufacturer {
// 			slug {
// 				current
// 			}
// 			title
// 			color {
// 				hex
// 			}
// 		}
// 		shortDescription
// 		image {
// 			asset {
// 				gatsbyImageData(width: 256, layout: FIXED, placeholder: BLURRED)
// 			}
// 		}
// 	}
// `

export default ProductCard
