import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import ProductCard from '../components/product-card'
import ProductFilter from '../components/product-filter'
import SEO from '../components/seo'
import { getSlug } from '../utils/url'

const ProductList = () => {
	const data = useStaticQuery(graphql`
		query ProductListData {
			allProduct: allSanityProduct(sort: { fields: _createdAt, order: DESC }) {
				edges {
					node {
						...ProductCardDataFragment
					}
				}
			}

			allSanityManufacturer {
				edges {
					node {
						slug {
							current
						}
						title
					}
				}
			}
		}
	`)

	const manufacturers = data.allSanityManufacturer.edges.map(
		(edge) => edge.node
	)

	return (
		<Layout>
			<SEO title="Produtos" />
			<h1 className="uk-heading-line">
				<span>Produtos</span>
			</h1>
			<div uk-filter="target: #products">
				<ProductFilter manufacturers={manufacturers} />

				<div id="products">
					{data.allProduct.edges.map(({ node }) => (
						<ProductCard key={node.slug.current} product={node} />
					))}
				</div>
			</div>
		</Layout>
	)
}

export default ProductList
